export default defineNuxtRouteMiddleware(async (to) => {
  // init supabase client
  const supabase = useSupabaseClient()

  const { data: { user } } = await supabase.auth.getUser()

  if (!user) {
    console.log('user null')
    return navigateTo(`/login?returnUrl=${to.fullPath}`)
  }

  // lookup user roles if necessary
  const allowedRoles = to?.meta?.auth || []
  if (allowedRoles.length > 0) {
    const { data: userRolesData, error } = await supabase
      .from('UserRoles')
      .select('role')
      .eq('user_id', user.id)

    if (error) {
      console.error('queryUserRoles Failed with error: ', error)
      return abortNavigation('Failed to lookup roles')
    }

    if (!userRolesData.some(r => allowedRoles.includes(r.role))) {
      console.log('user missing required role')
      return abortNavigation(createError({ statusCode: 403, statusMessage: 'You do not have permission to view this page' }))
    }
  }
})
